.checkout {
  display: flex;
  padding: 20px;
  background-color: white;
  height: max-content;
}

.checkout__ad {
  width: 100%;
  max-width: 1500px;

  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.checkout__title {
  margin-right: 10px;
  padding: 10px;
  border-bottom: 1px solid lightgray;
}
